import React from 'react'
import {Link} from 'react-router-dom'
import BannerImage from '../assests/backgroundimage.jpeg'
import '../styles/Home.css'

function Home() {
  return (
    <div className='home' style={ { backgroundImage: `url(${BannerImage})`} }>
        <div className='headerContainer'>
            <h1>HungerGoal</h1>
            <p>Homemade & Handmade</p>
            <Link to='/products'>
                <button>Check Out</button>
            </Link>
            
        </div>
    </div>
  )
}

export default Home