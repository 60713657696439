import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterInIcon from '@mui/icons-material/Twitter';
import '../styles/Footer.css'
import Link from '@mui/material/Link';


function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'>
            <Link href="https://www.instagram.com/cuisineculture_by_akriti?igsh=ZTdwcGVlY2hqamUz" className='instagram' target="_blank" >
                <InstagramIcon/>
            </Link>
            <Link href="https://www.facebook.com/" className='facebook' target="_blank" >
                <FacebookIcon/>
            </Link>
            <Link href="https://in.linkedin.com/" className='linkedin' target="_blank" >
                <LinkedInIcon/>
            </Link>
            <Link href="https://twitter.com/" className='twitter' target="_blank" >
                <TwitterInIcon/>
            </Link>
        </div>
        <p> &copy; 2024 hungergoal.com</p>
    </div>
  )
}

export default Footer