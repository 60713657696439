import React, {useState} from 'react'
import Logo from "../assests/logo.jpeg"
import { Link, NavLink } from 'react-router-dom'
import ReorderIcon from '@mui/icons-material/Reorder';
import '../styles/Navbar.css'

function Navbar() {

    const [openLinks, setOpenLinks] = useState(false);

    const toggleNavbar = () => {
        setOpenLinks(!openLinks);
    }

  return (
    <div className='navbar'>
        <div className='leftside' id={openLinks ? "open" : "close"}>
            <div className='logo-circle'><img src={Logo} alt='Logo' className='logo-img'/></div>
            <div className='hiddenLinks'>
                <Link to='/'>Home</Link>
                <Link to='/products'>Products</Link>
                <Link to='/about'>About</Link>
                <Link to='/contact-us'>Contact Us</Link>
            </div>
        </div>
        <div className='rightside'>
            <Link to='/'>Home</Link>
            <NavLink to='/products'>Products</NavLink>
            <NavLink to='/about'>About</NavLink>
            <NavLink id='contactus' to='/contact-us'>Contact Us</NavLink>
            <button onClick={toggleNavbar}>
                <ReorderIcon/>
            </button>
        </div>
    </div>
  )
}

export default Navbar