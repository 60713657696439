import React from 'react'
import '../styles/About.css'
import AboutImg from '../assests/about.jpeg'

function About() {
  return (
    <div className='about'>
        <div className='aboutTop' style={ { backgroundImage: `url(${AboutImg})`} }></div>
        <div className='aboutBottom'>
            <h1>ABOUT US</h1>
            <p>Welcome to HungerGoal, where passion meets flavors! We're dedicated to crafting homemade and handmade snacks that celebrate the artistry of culinary creation. Our journey began with a love for sharing delicious, handcrafted treats like peri peri kaju, kesar badam biscuits, and an array of delightful snacks made from the heart. Each recipe is infused with tradition, care, and a blend of high-quality ingredients, ensuring every bite is an experience worth savoring. At HungerGoal, we believe in the joy of homemade goodness, and our mission is to bring that joy to your snacking moments.</p>
        </div>
    </div>
  )
}

export default About