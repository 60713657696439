import React from 'react'
import LaunchIcon from '@mui/icons-material/Launch';
import {Link} from 'react-router-dom'

function ProductItem({image, name, price, url}) {
  return (
    <div className='productItem'>
      <div style={ { backgroundImage: `url(${image})`} }></div>
      <h1>{name}</h1>
        <p>₹{price}</p> 
        <Link to={url} target='_blank'><button> Order Now</button></Link>
    </div>
  )
}

export default ProductItem