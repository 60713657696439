import React from 'react'
import {ProductList} from '../utils/ProductList'
import ProductItem from '../components/ProductItem'
import '../styles/Products.css'
import LaunchIcon from '@mui/icons-material/Launch';


function Products() {
  return (
    <div className='products'>
        <h1 className='productsTitile'> Our Products</h1>
        <div className='productList'>
            {ProductList.map((productItem, key) => {
                return ( 
                    <ProductItem 
                        key={key} 
                        image={productItem.image} 
                        name={productItem.name} 
                        price={productItem.price}
                        url={productItem.url}
                    />
                )
            })}
        </div>
    </div>
  )
}

export default Products