import PeriPeriKaju from '../assests/PeriPeriKaju.jpeg'
import PistaBadamCookies from '../assests/PistaBadamCookies.jpeg'
import MasalaKajuMathari from '../assests/MasalaKajuMathari.jpeg'
import RasmalaiCookies from '../assests/RasmalaiCookies.jpeg'
import MixedSeedsCookies from '../assests/mixedseedscookies.jpeg'
import ButterJeeraCookies from '../assests/ButterJeeraCookies.webp'


export const ProductList = [
    {
        name: "Peri Peri Kaju",
        image: PeriPeriKaju,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    },
    {
        name: "Pista Badam Cookies",
        image: PistaBadamCookies,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    },
    {
        name: "Masala Kaju Mathari",
        image: MasalaKajuMathari,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    },
    {
        name: "Butter Jeera Cookies",
        image: ButterJeeraCookies,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    },
    {
        name: "Rasmalai Cookies",
        image: RasmalaiCookies,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    },
    {
        name: "Mix Seeds Cookies",
        image: MixedSeedsCookies,
        price: 160,
        url: "https://www.amazon.in/Roasted-Flavoured-Cashews-400Gram-Premium/dp/B09C4FDH3B/ref=sr_1_1_sspa?crid=1Z8R86DAIFZ5P&keywords=peri+peri+kaju&qid=1704491426&sprefix=peri+peri+kaju%2Caps%2C195&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1"
    }
]